<template>
    <div class="pop-com">
        <div>
            <div>
                <img :src="product_image">
                <div class="confirm-button" @click="toView()"></div>
                <div class="close-button" @click="closePop()">
                    <img src="https://qn.ky3d.com/chatgpt_uni/close_model.png">
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    props:{
        tem_id:Number,
        product_image:String
    },
    data(){
        return{

        }
    },
    created(){

    },
    methods:{
        toView(){
            toNhView();
        },
        closePop(){
            this.$emit('closePop');
        }
    }
}
</script>

<style>
    .pop-com{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.6);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pop-com > div{
        width: 100%;
        height: 80%;
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .pop-com > div > div{
        width: 80%;
        height: 100%;
        position: relative;
    }
    .pop-com > div > div > img{
        width: 100%;
        height: 100%;
        object-fit: contain;

    }
    .pop-com > div > div > .confirm-button{
        width: 50%;
        height: 20%;
        position: absolute;
        bottom: 5%;
        right: 10%;
    }
    .pop-com > div > div > .close-button{
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
    }
    .pop-com > div > div > .close-button > img{
        width: 40px;
        height: 40px;
        object-fit: contain;
    }
</style>